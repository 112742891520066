import "./showmore.css";

const ShowMoreModal = ({ show, onClose, referral }) => {
    if (!show) {
        return null; // If modal is not visible, return nothing
    }

    // Function to stop event propagation when clicking inside the modal content
    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={handleModalClick}>
                {/* Close button */}
                <button className="w-fit ml-auto text-black p-[5px] rounded-lg mt-2" onClick={onClose}>
                    X
                </button>
                <div className="border-solid p-[5px]">
                    <p>Users with referral link : {referral.count}</p>
                    <p>Users with Projects : {referral.count}</p>
                    <p>Collaborators : {referral.count}</p>
                    <p>Users Subscribed : {referral.count}</p>
                </div>
            </div>
        </div>
    );
};

export default ShowMoreModal;
