import React, { Component, useState, useEffect } from "react";

import { API_BASE_URL, APP_BASE_URL, APP_REFER_URL } from './config/apiConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import ShowMoreModal from "./showmoreModal";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";

function Funnel({ }) {


  const [referal, setReferral] = useState([]);
  const [referralCounts, setReferralCounts] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [usersWithAuthCode, setUsersWithAuthCode] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [AllProjects, setAllProjects] = useState(0);
  const [usersWithMultipleProjects, setUsersWithMultipleProjects] = useState(0);
  const [projectsWithNonOwnerTeamMembers, setProjectsWithNonOwnerTeamMembers] = useState(0);
  const [inactiveUsersOne, setInactiveUsersOne] = useState(0);
  const [inactiveUsersTwo, setInactiveUsersTwo] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedReferral, setSelectedReferral] = useState(null);

  // Function to open the modal and set the selected referral
  const handleShowMore = (referral) => {
    setSelectedReferral(referral);
    setShowModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedReferral(null);
  };


  const fetchCounts = async () => {
    try {
      const userCountRes = await fetch(API_BASE_URL + '/api/funnel/usersCount');
      const inactiveUserOneCountRes = await fetch(API_BASE_URL + '/api/funnel/inactive/7');
      const inactiveUserTwoCountRes = await fetch(API_BASE_URL + '/api/funnel/inactive/15');
      const usersWithAuthCodeRes = await fetch(API_BASE_URL + '/api/funnel/cardCount');
      const projectCountRes = await fetch(API_BASE_URL + '/api/funnel/uniqueProjectsCount');
      const allProjectCountRes = await fetch(API_BASE_URL + '/api/funnel/projectsCount');
      const usersWithMultipleProjectsRes = await fetch(API_BASE_URL + '/api/funnel/multipleProjectsCount');
      const projectsWithNonOwnerTeamMembersRes = await fetch(API_BASE_URL + '/api/funnel/teamProjectsCount');
      const userCountData = await userCountRes.json();
      const inactiveUserOneCountDate = await inactiveUserOneCountRes.json();
      const inactiveUserTwoCountDate = await inactiveUserTwoCountRes.json();
      const usersWithAuthCodeData = await usersWithAuthCodeRes.json();
      const projectCountData = await projectCountRes.json();
      const allProjectCountData = await allProjectCountRes.json();
      const usersWithMultipleProjectsData = await usersWithMultipleProjectsRes.json();
      const projectsWithNonOwnerTeamMembersData = await projectsWithNonOwnerTeamMembersRes.json();
      console.log(projectsWithNonOwnerTeamMembers);
      setTotalUsers(userCountData.totalUsers);
      setInactiveUsersOne(inactiveUserOneCountDate.inactiveUserCount);
      setInactiveUsersTwo(inactiveUserTwoCountDate.inactiveUserCount);
      setUsersWithAuthCode(usersWithAuthCodeData.usersWithAuthCode);
      setTotalProjects(projectCountData.uniqueUsers);
      setAllProjects(allProjectCountData.totalProjects);
      setUsersWithMultipleProjects(usersWithMultipleProjectsData.usersWithMultipleProjects);
      setProjectsWithNonOwnerTeamMembers(projectsWithNonOwnerTeamMembersData.projectsWithNonOwnerTeamMembers);
    } catch (error) {
      console.error('Error fetching counts:', error);
    }
  };
  const [decodedToken, setDecodedToken] = useState(null);
  
  const token = localStorage.getItem("access_token")
  const decoded_token = jwtDecode(token)

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token); // Decode the token
        setDecodedToken(decoded);
      } catch (error) {
        console.error("Invalid Token", error);
      }
    }
  }, [token]);
  

  const navigate = useNavigate();

  useEffect(() => {
    checkAuth(navigate);
  }, [navigate]);

  const checkAuth = (navigate) => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      navigate('/');  
    }
  };


  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    refFor: ''  // Initialize refFor in the state
  });

  const handleLogout = () => {
    localStorage.clear();

    navigate('/');
  };


  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value  // Update formData with new input value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    getLink(formData);


  };

  const getLink = async (data) => {
    setLoading(true);
    try {


      console.log(data);
      console.log(JSON.stringify(data));
      const response = await fetch(API_BASE_URL + '/api/user/wenm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      // const data = response.json();

      if (response.status === 200) {

        console.log(response.status);
        console.log(response);
        setLoading(false);
        const responseData = await response.json();
        setReferral(APP_BASE_URL + '/' + responseData.referralCode.referralCode)// Parse JSON response
        console.log(responseData.referralCode)// Parse JSON response

      } else {
        const result = await response.json();
        setLoading(false);
        console.log(result);


        //toast.error(result['error']);
        //console.error('Error:', result['error']);
        //console.error('Failed to create User');
      }
    } catch (error) {
      setLoading(false);
      console.error('An error occurred:', error);
    }
  };




  useEffect(() => {
    // Fetch referral counts from the backend
    const fetchReferralCounts = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/user/multiCount`);

        // Check if the response is okay (status in the range 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response}`);
        }

        const data = await response.json(); // Parse the JSON response
        console.log(data);
        setReferralCounts(data); // Update the state with the fetched data
      } catch (error) {
        console.error('Error fetching referral counts:', error);
      }
    };

    const intervalId = setInterval(fetchReferralCounts, 10000); // Poll every 10 seconds

    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, []);



  const downloadCSV = async (days) => {
    try {
      // Make a GET request to the server with the dynamic 'days' parameter
      const response = await axios.get(`${API_BASE_URL}/api/funnel/inactive/csv/${days}`, {
        responseType: 'blob', // Important to handle binary data
      });

      // Create a URL for the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // Set the filename for the downloaded file
      link.setAttribute('download', `inactive_users_${days}_days.csv`);

      // Programmatically click the link to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the CSV file', error);
    }
  };


  useEffect(() => {
    // Fetch data initially
    fetchCounts();

    // Set up polling to fetch data every 10 seconds (10000 milliseconds)
    const intervalId = setInterval(fetchCounts, 10000);


    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="container">
    <div className="w-fit ml-auto mt-[20px] " >
        <button
          onClick={handleLogout}
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50 transition m-auto "
        >
          Log Out
        </button>
    </div>
      <h1 className="funHead">The funnel</h1>
      <div className="row">
        <div className="col-md-4">
          <div className="funnelHold">
            <p className="funnelP1">{totalUsers}</p>
            <p className="funnelP2">Total Users</p>
          </div>
        </div>

        <div className="col-md-4">
          <div className="funnelHold">
            <p className="funnelP1">{usersWithAuthCode}</p>
            <p className="funnelP2">Users with Cards</p>
          </div>
        </div>

        <div className="col-md-4">

          <div className="funnelHold">
            <p className="funnelP1">{totalProjects}</p>
            <p className="funnelP2">Users with Projects</p>
          </div>

        </div>

        <div className="col-md-4">

          <div className="funnelHold">
            <p className="funnelP1">{AllProjects}</p>
            <p className="funnelP2">Total Project</p>
          </div>

        </div>


        <div className="col-md-4">

          <div className="funnelHold">
            <p className="funnelP1">{usersWithMultipleProjects}</p>
            <p className="funnelP2">Users with Multiple Projects</p>
          </div>

        </div>

        <div className="col-md-4">

          <div className="funnelHold">
            <p className="funnelP1">{projectsWithNonOwnerTeamMembers}</p>
            <p className="funnelP2">Collaborators</p>
          </div>

        </div>


        <div className="col-md-4">

          <div className="funnelHold">
            <p className="funnelP1">{inactiveUsersOne}</p>
            <p className="funnelP2">Inactive Users(one Week)</p>
            <button onClick={() => downloadCSV(15)} className="csvB">
              Download
            </button>
          </div>

        </div>

        <div className="col-md-4">

          <div className="funnelHold">
            <p className="funnelP1">{inactiveUsersTwo}</p>
            <p className="funnelP2">Inactive Users(two Weeks)</p>
            <button onClick={() => downloadCSV(15)} className="csvB">
              Download
            </button>
          </div>

        </div>





      </div>

      <div className="row">
        <div className="col-md-6">
          {/* Check if user has permission to generate a referral link */}
          {decodedToken && (decodedToken.permission === "Super Admin" || decodedToken.permission === "Marketer") ? (
            <div className="bordRg">
              <p className="text-center">Generate Referral Link</p>

              <form onSubmit={handleSubmit}>
                <div className="inputs-container">
                  <p>{referal}</p>

                  <input
                    type="text"
                    id="refFor"
                    value={formData.refFor}
                    onChange={handleChange}
                    className="custom-input bor"
                  />

                  <p className='lab'>What For</p>
                </div>

                <button type="submit" className='btn loginBtn' disabled={loading}>
                  {loading && <FontAwesomeIcon icon={faCircleNotch} className='fa-spin' />}
                  {!loading && <span>Submit</span>}
                </button>
              </form>
            </div>
          ) : (
            <p className="m-auto w-fit lg:mt-[150px] " >You do not have permission to generate a referral link.</p>
          )}
        </div>

        <div className="col-md-6">
          <div className="bordRg" style={{ overflowY: "auto" }}>
            <p className="text-center ">View Data</p>
            {referralCounts.map((referral, index) => (
              <div key={index} className=" flex justify-between ">
                {/* Display the referralFor name */}
                <div>
                  <p className="appCs">{referral.referralFor}</p>
                  {/* Display the count */}
                  <p style={{ fontSize: 13 }}>{APP_REFER_URL}/{referral.referralCode}</p>
                </div>
                <div>
                  <button
                    className="bg-black rounded-lg p-[5px] text-white"
                    onClick={() => handleShowMore(referral)}
                  >
                    Show more
                  </button>

                </div>
              </div>
            ))}

          </div>
        </div>

      </div>

      <ShowMoreModal
        show={showModal}
        onClose={handleCloseModal}
        referral={selectedReferral}
      />

    </div>
  );
}




export default Funnel;
