// apiConfig.js
//const API_BASE_URL = 'http://67.225.189.98:3001';
//const API_BASE_URL = 'http://67.225.189.98:3001';
//const API_BASE_URL = 'https://craddd-cx3n.onrender.com';
// const API_BASE_URL = 'https://app.craddule.com';
const APP_BASE_URL = 'http://localhost:3001';
const APP_REFER_URL = 'https://craddule.com';
const API_BASE_URL = 'https://api.cradduleapi.com.ng';

module.exports= {API_BASE_URL, 
    APP_BASE_URL, APP_REFER_URL
};
